/*
.cmp-product-thumb  {
  .thumb-content {
    text-align: center;
    .btn-group {
      width: 100%;
      .row {
        width: 100%;
        margin: 0;
        .info-button, .basket-button {
          button {
            border: 1px solid #dbdbdb;
            background: transparent;
            margin-top: .5rem;
            padding: .35rem;
            width: 100%;
          }
        }

        .info-button {
          width: 100%;
          &.two-buttons-in-row {
            flex: 0 0 49%;
            padding: 0;
            @media (min-width: 768px) and (max-width: 1148px) {
              flex: 0 0 48%;
            }
          }
        }
        .basket-button {
          flex: 0 0 48%;
          padding: 0;
          margin-left: .5rem;
        }
        .info-button, .basket-button {
          @media (max-width: 575px) {
            flex: 0 0 85% !important;
            max-width: 85% !important;
            margin: auto;
          }
        }
      }
    }
    .prices {
      display: block;
      .price {
        color: #000;
      }
      .price-view-port {
        .crossprice {
          font-size: 100%;
          font-weight: 100;
          color: #000;
        }
      }
    }
    .thumb-title {
      text-align: center;
      height: 1rem;
      overflow: hidden;
      margin-bottom: 1.5rem;
      @media (max-width: 575px) {
        height: 4.5rem;
      }
    }
    .category-unit-price {
      font-size: 97%;
    }
  }
    .badge:empty, .tag:empty {
      display: block;
      float: right;
      margin-top: -22px;
      width: 15px;
      height: 15px;
      font-size: 10px;
      padding: 0.15rem;
    }
}

.product-list.grid li > div {
  width: 100%;
}

.page-home-category {
  .cmp-product-thumb {
    .info-button, .basket-button {
      @media (min-width: 768px) and (max-width: 1148px) {
        flex: 0 0 85% !important;
        max-width: 85% !important;
        margin: 0 auto !important;
      }
    }
  }
}

.add-to-wish-list-grid {
  position: absolute;
  right: 1.5rem;
  z-index: 99;
  top: .5rem;
  font-size: 1.5rem;
  background: rgba(255,255,255,.85);
  padding: .5rem 0.85rem .45rem 0.7rem;
  border-radius: 2rem;
  height: 42px;
  width: 42px;
}
*/

.cmp-product-thumb {
  .badge:empty, .tag:empty {
/*    display: block;
    float: right;
    margin-top: -20px;*/
    width: 15px;
    height: 15px;
    font-size: 10px;
    padding: 0.15rem;
    border-radius: 1rem;
    margin-left: .25rem;
  }
  .delivery-block {
    display: flex;
    flex-direction: row;
    float: right;
    margin-top: -1.25rem;
    span {
      display: inline-flex;
      i {
        color: #6c757d;
      }
    }
  }
}
