.single .quantity-input-container, .single .qty-box-container {
  .input-group {
    flex-wrap: nowrap;
    display: flex;
    justify-content: right;
    button {
      background: #fff;
      /*border: 1px solid #ccc;*/
      border-color: transparent;
      color: #4d4d4d;
      padding: .5rem 1rem;
    }
    input {
      color: #000;
      /*border: 1px solid #ccc;*/
      border-color: transparent;
      border-radius: 0;
      max-width: 1.75rem;
      &:focus {
       /* border: 1px solid #000 !important;*/
        border-color: transparent;
        border-radius: 0 !important;
      }
    }
    &.input-group-custom {
      .decrease-value {
        button {
          border-left: 2px solid #dbdbdb !important;
          border-top: 2px solid #dbdbdb !important;
          border-bottom: 2px solid #dbdbdb !important;
          border-radius: 0.25rem 0 0 0.25rem;
          border-right: 0;
          font-size: 1.1rem;
        }
      }
      input.qty-input {
        border-top: 2px solid #dbdbdb !important;
        border-bottom: 2px solid #dbdbdb !important;
        border-right: 0;
        border-left: 0;
      }
      .increase-value {
        button {
          border-right: 2px solid #dbdbdb !important;
          border-top: 2px solid #dbdbdb !important;
          border-bottom: 2px solid #dbdbdb !important;
          border-radius: 0 0.25rem 0.25rem 0;
          border-left: 0;
          font-size: 1.1rem;
        }
      }
    }
  }
}


.single .add-to-basket-container > button {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
}
