.normalmenu {
  .main-navbar-collapsable {
    li {
      display: grid;
    }
    .mainmenu {
      & > li {
        position: relative;
          & > ul {
          z-index: 99999;
          overflow: initial;
        }
      }

      .collapse {
        overflow: unset;
        overflow-y: unset;
        min-width: 17rem;
        margin-top: 4rem;

        li {
          &:hover {
            & > a {
              background: #fff;
              color: #000;
            }

            & > ul.collapse-inner {
              /*display: block;*/
              visibility: visible;
              opacity: 1;
              & > li {
                a {
                  min-width: 14rem;
                }
                &:hover {
/*                  background: #fff;*/
                }
              }
            }
          }

          &.level1 {
            position: relative;
            background: #fff;
            color: #008ebd;
            & > ul {
              position: absolute;
              left: 100%;
              z-index: 99999;
              /*display: none;*/
              opacity: 0;
              top:0;
              visibility: hidden;
              background: #fff;
              color: #008ebd;
              li {
                &.level2 {
                  position: relative;
                  ul {
                    position: absolute;
                    opacity: 0;
                    left: 100%;
                    top:0;
                    background: #fff;
                    color: #008ebd;
                    li {
                      position: relative;
                      &.level3 {
                        ul {
                          position: absolute;
                          opacity: 0;
                          left: 100%;
                        }
                        &:hover {
                          ul {
                            opacity: 1;
                          }
                        }
                      }
                    }
                  }
                  &:hover {
                    ul {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
          i {
            float: right;
          }
        }
      }
    }
  }
}

/*.brand-wrapper a {
  padding: 0;
}*/
