.search-mobile {
  --easing: cubic-bezier(0.4, 0, 0.2, 1);
  --font-size: 2rem;
  --color: var(--color-foreground);
  --color-highlight: white;
  --transition-time-icon: 0.2s;
  --transition-time-input: 0.3s 0.25s;

  &:not(:focus-within) {
    --transition-time-input: 0.2s 0s;
  }

  @media (prefers-reduced-motion: reduce) {
    --transition-time-icon: 0s !important;
    --transition-time-input: 0s !important;
  }

  $root: &;

  border-radius: 999px;
  border: 2px solid transparent;
  display: flex;
  transition: border-color var(--transition-time-icon) linear,
  padding var(--transition-time-input) var(--easing);

  &__input {
    background: transparent;
    border: none;
    color: var(--color-highlight);
    font-size: var(--font-size);
    opacity: 0;
    outline: none;
    padding: 0;
    transition: width var(--transition-time-input) var(--easing),
    padding var(--transition-time-input) var(--easing),
    opacity var(--transition-time-input) linear;
    width: 0;

    &::placeholder {
      color: var(--color);
      opacity: 0.75;
    }
  }

  &__icon-container {
    height: calc(var(--font-size) + 2rem);
    position: relative;
    width: calc(var(--font-size) + 2rem);
  }

  &__label,
  &__submit {
    color: var(--color);
    cursor: pointer;
    display: block;
    height: 100%;
    padding: 0;
    position: absolute;
    width: 100%;

    &:hover,
    &:focus,
    &:active {
      color: var(--color-highlight);
    }
  }

  &__label {
    transition: transform var(--transition-time-icon) var(--easing), color 0.1s;
  }

  &__submit {
    background: none;
    border-radius: 50%;
    border: none;
    box-shadow: 0 0 0 4px inset transparent;
    display: none;
    outline: none;
    transition: color 0.1s, box-shadow 0.1s;

    svg {
      transform: scale(0.5);
    }

    &:focus {
      box-shadow: 0 0 0 4px inset var(--color-highlight);
    }
  }

  &:focus-within {
    border-color: var(--color);

    #{$root} {
      &__input {
        opacity: 1;
        padding: 0 1rem 0 2rem;
        width: calc(var(--font-size) * 12);
      }

      &__label {
        transform: scale(0.5);
      }

      &__submit {
        animation: unhide var(--transition-time-icon) steps(1, end);
        display: block;
      }
    }
  }
}

@keyframes unhide {
  from {
    height: 0;
    opacity: 0;
  }

  to {
    height: auto;
    opacity: 1;
  }
}
